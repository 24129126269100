<template>
  <div>
    <b-card>
      <b-col cols="12">
      <span>Add staff that will be working from this location.</span>
      </b-col>

      <b-col  cols="12" md="12" class="pb-0 mt-1">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
          v-model="filters['search']"
           placeholder="Search" />
        </b-input-group>
      </b-col>
     <pre>{{checkedStaffMembers}}</pre>
<!--      <vue-perfect-scrollbar-->
<!--          :settings="perfectScrollbarSettings"-->
<!--          class="todo-task-list-wrapper list-group scroll-area"-->
<!--          style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"-->
<!--      >-->
        <ul class="list-group">

          <li
              v-for="(role,index) in pcnStaff"
              :key="role.id"
              class="todo-item  list-group-item py-1"
          >

            <b-form-checkbox
                :checked="role.isCompleted"
                v-model="checkedStaffMembers"

            >
              <span class="todo-title">{{index }}</span>
            </b-form-checkbox>



              <span
                  v-for="(person,index) in role"
                  class="todo-item list-group-item py-1"
              >
                <b-form-checkbox
                    :value="person"
                    v-model="checkedStaffMembers"
                    @change="getStaff(person)"
                >

                <span>{{person.user.first_name + " " + person.user.last_name}}</span>
                  <span>{{" - " +person.practice.name}}</span>
                  <b-badge v-if="person.is_locum == 1" class="mr-1 mb-1" style="padding:0.8rem" variant="light-info">
                  <span class="text-dark font-weight-700">L</span>
                  </b-badge>
                </b-form-checkbox>

              </span>


          </li>
        </ul>

<!--      </vue-perfect-scrollbar>-->

    </b-card>
  </div>
</template>

<script>
import locations from '@/apis/modules/locations'

import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BForm,
  BFormCheckbox,
  BBadge
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BForm,
    BFormCheckbox,
    BBadge
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data() {
      return{
        pcnStaff: [],
        checkedStaffMembers: [],
        filters: {},
        filter: null,
        filterOn: [],
        staff: {
          user_id:null,
          practice_id:null,
          is_locum:false
        },
        detailsOfMember:[]

      }
  },


  methods:{
    getStaff(record) {
      this.detailsOfMember.push(record.is_locum,record.user_id,record.practice_id)
    },

   async getPcnStaffJobRoleWise () {
       const response=await locations.getPcnStaffJobRoles(this.filterQuery)
        this.pcnStaff = response.data.data
    },
    getFormData() {
      return [this.staff]
    },
    filterQueryUpdate () {
      this.$refs.refUserListTable.refresh()
    },
  },

  mounted () {
    this.getPcnStaffJobRoleWise()
  }
}
</script>
