import api from '../axios';
const resource = '/api/pcn';

export default {
  getNonConnectedLocation: () => api.get(`${resource}/non-connected-or-non-invited-practices`),
  getConnectedLocation: () => api.get(`${resource}/network-practices`),

  getLocations: (page, paginate, filterQuery) => api.get(
      `${resource}/locations?${filterQuery}&page=${page}&paginate=${paginate}`),

  getPcnStaffJobRoles: (filterQuery) => api.get(`${resource}/staff-job-roll-wise?${filterQuery}`),

  getDetailsOfLocation: (id) => api.get(`${resource}/locations/${id}`),

  createLocation: (payload) => api.post(`${resource}/locations`, payload),

  editLocation: (payload, id) => api.put(`${resource}/locations/${id}`, payload),

  getLocationStaffs: (id, page, paginate) => api.get(`${resource}/locations/${id}/staff?page=${page}&paginate=${paginate}`),
  getLocationStaffsWithoutPagination: (id, page, paginate) => api.get(`${resource}/locations/${id}/staff/without-pagination`),
};

