<template>
  <div>
    <b-card>
      <validation-observer
      ref="createLocationForm">
        <b-form
            @submit.prevent>
      <b-row>
       <b-col cols="12">
        <b-form-group
            label="Name"
            label-for="v-name"
        >
          <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
          >
          <b-form-input
              id="v-name"
              placeholder="e.g.General Medical Practice"
              v-model="location.name"
          />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Address"
            label-for="v-address"
        >
          <b-form-input
              id="v-address"
              placeholder="e.g.Guilt Street 15"
              v-model="location.address"
          />
        </b-form-group>
      </b-col>

        <b-col md="6">
          <b-form-group
              label="City"
              label-for="city"
          >
            <b-form-input
                id="city"
                placeholder="Select"
                v-model="location.city"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Postal Code"
              label-for="postal-code"
          >
            <b-form-input
                id="postal-code"
                placeholder="e.g.PR8 8UW"
                v-model="location.postal_code"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
              label="Telephone"
              label-for="telephone"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PhoneIcon"/>
              </b-input-group-prepend>
            <b-form-input
                id="telephone"
                placeholder="xxx xxxx xxxx"
                v-model="location.telephone"
            />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Email"
              label-for="email"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon"/>
              </b-input-group-prepend>
            <b-form-input
                id="email"
                placeholder="contact@mail.co.uk"
                v-model="location.email"
            />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label="Service charge per hour"
              label-for="service-charge-per-hour"
          >
            <b-input-group class="input-group-merge"
                           prepend="£">
              <b-input-group-prepend is-text>
                <feather-icon icon=""/>
              </b-input-group-prepend>
              <b-form-input
                  id="service-charge-per-hour"
                  placeholder=""
                  v-model="location.service_charge"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

      </b-row>


        <b-col cols="12">
          <b-row>
        <div class="bank-details">
        Bank Details
        </div>

        <div class="optional-class">
          Optional
        </div>
          </b-row>
        </b-col>

      <b-col cols="12">
        <div class="mt-3">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Bank Name"
                  label-for="v-bank-name"
              >
                <b-form-input
                    id="v-bank-name"
                    placeholder="e.g.Bank of England"
                    v-model="bank_details.name"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Account Number"
                  label-for="account-number"
              >
                <b-form-input
                    id="account-number"
                    placeholder="xxxxxxxx"
                    v-model="bank_details.account_number"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Sort Code"
                  label-for="sort-code"
              >
                <b-form-input
                    id="sort-code"
                    placeholder="xx-xx-xx"
                    v-model="bank_details.sort_code"

                />
              </b-form-group>
            </b-col>
          </b-row>
<!--          <b-button-->
<!--              variant="primary"-->
<!--          >-->
<!--            <span class="align-middle">Create Location</span>-->

<!--            <feather-icon-->
<!--                icon="ChevronRightIcon"-->
<!--                class="ml-50"-->
<!--            />-->
<!--          </b-button>-->

          <b-form-group class="mb-0">
            <b-button
                class="btn-sm-block"
                variant="primary"
            >

              <span class="align-middle mb-3">Next</span>
              <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50 text-light"

              />
            </b-button>
          </b-form-group>


        </div>

      </b-col>


        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BForm
} from 'bootstrap-vue';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {alphaNum, email, required} from '@validations';
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BForm,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data() {
    return {
      required,
      alphaNum,
      email,
      location: {
           name: '',
           address: '',
           city: '',
           postal_code: '',
           telephone: '',
           email: '',
           service_charge: ''
      },
      bank_details: {
           name: '',
        account_number: '',
           sort_code: ''
      }
    }
  },

  methods: {
    getFormData() {
       return [this.location,this.bank_details]
      // return [{'location': this.location},{'bank_details': this.bank_details}]
    }
  }
}
</script>

<style>
.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 0px;
  margin-top: 3px;
  /*top: calc(50% - 21px/2 + 162px);*/

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height, or 150% */
  text-align: right;

  /* $dark-grey */
  color: #949494;
}

.bank-details {
  position: absolute;
  /*height: 20.19px;*/
  left: 0%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/

  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  /* Dark */
  color: #171822;
}

</style>
