<template>
  <b-sidebar
      id="add-new-user-sidebar"
      ref="AddLocationSidebar"
      :visible="isAddNewLocationSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-new-location-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <validation-observer
          ref="CreateLocationForm"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Create Location
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="cancel()"
          />

        </div>

        <!-- BODY -->
        <b-alert
            :show="true"
            class="mb-0 mt-2 rounded ml-2 mr-2"
            variant="info"
        >
          <!--        <div class="alert-body text-dark">-->

          <!--          <b-badge class="mr-1 " style="padding:0.5rem" variant="primary">-->
          <!--            <span class="text-light font-weight-700">1</span>-->
          <!--          </b-badge>-->
          <!--          General Details-->
          <!--          <b-badge class="mr-1 " style="padding:0.5rem" variant="secondary">-->
          <!--            <span class="text-light font-weight-700">2</span>-->
          <!--          </b-badge>-->

          <!--          Staff-->
          <!--        </div>-->
          <!--        <div class="content-header-left mb-2 col-md-9 col-12">-->
          <div class="row breadcrumbs-top">
            <!--            <div class="col-12">-->
            <div class="breadcrumb-wrapper text-dark">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <!--                    <a href="/" class="router-link-active" target="_self"></a>-->
                  <!--                    <a href="#" target="_self" class="">-->
                  <b-badge :variant="current_tab == 'General-Details'? 'primary' : 'light-dark'" class="mr-1 ml-1 "
                           style="padding:0.5rem">
                    <span :class="current_tab == 'General-Details'? 'text-white' : 'text-dark'">1</span>
                  </b-badge>
                  <span :class="current_tab == 'General-Details'? 'text-primary' : 'text-dark'" style="cursor: pointer;"
                        @click.prevent="changeTab('General-Details')"> General Details </span>
                  <!--               </a>-->
                <li class="breadcrumb-item active">
                  <span aria-current="location">

                <b-badge :variant="current_tab == 'Staff'? 'primary' : 'light-dark'" class="mr-1 "
                         style="padding:0.5rem">
               <span :class="current_tab == 'Staff'? 'text-white' : 'text-dark'">2</span>
                </b-badge>
                  <span :class="current_tab == 'Staff'? 'text-primary' : 'text-dark'" style="cursor: pointer;"
                        @click.prevent="changeTab('Staff')">Staff</span>


                </span>
                </li>
              </ol>
            </div>
          </div>
          <!--        </div>-->
          <!--        </div>-->
        </b-alert>
        <b-overlay
            :show="loading"
            rounded="sm"
        >
          <!--      general details -->
          <div v-show="current_tab === 'General-Details'" class="m-2">
            <b-card>
              <b-row>
                <b-col class="mb-1" cols="12">
                  <span>Is the location for the service a network practice?</span>
                  <validation-provider
                      #default="{ errors }"
                      name="Is Practice"
                      rules=""
                  >
                    <b-form-checkbox
                        v-model="isLocationSamePractice"
                        class="float-left"
                        @change="geConnectedPractice(isLocationSamePractice)"
                    >

                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col v-if="practiceShow" cols="12">
                  <label> Select network practice</label>
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Network Practice"
                        rules=""
                    >
                      <v-select
                          v-model="selectedPractice"
                          :options="practicesOption"
                          label="title"
                          placeholder="Select Practice"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                      label="Name"
                      label-for="v-name"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                    >
                      <b-form-input
                          id="v-name"
                          v-model="location.name"
                          placeholder="e.g.General Medical Practice"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                      label="Address"
                      label-for="v-address"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Address"
                        rules="required|min_address"
                    >
                      <b-form-input
                          id="v-address"
                          v-model="location.address"
                          placeholder="e.g.Guilt Street 15"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                      label="City"
                      label-for="city"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                    >
                      <b-form-input
                          id="city"
                          v-model="location.city"
                          placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Postal Code"
                      label-for="postal-code"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Postal Code"
                        rules="required|postal_code"
                    >
                      <b-form-input
                          id="postal-code"
                          v-model="location.postal_code"
                          placeholder="e.g.PR8 8UW"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                      label="Telephone (optional)"
                      label-for="telephone"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Telephone"
                        rules="tel|min_tel|max_tel"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PhoneIcon"/>
                        </b-input-group-prepend>
                        <b-form-input
                            id="telephone"
                            v-model="location.telephone"
                            placeholder="xxx xxxx xxxx"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                      label="Email (optional)"
                      label-for="email"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="emailTest"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon"/>
                        </b-input-group-prepend>
                        <b-form-input
                            id="email"
                            v-model="location.email"
                            placeholder="contact@mail.co.uk"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                      label="Service charge per hour (optional)"
                      label-for="service-charge-per-hour"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Service Charge"
                        rules="numeric|max"
                    >
                      <b-input-group class="input-group-merge"
                                     prepend="£">
                        <b-input-group-prepend is-text>
                          <feather-icon icon=""/>
                        </b-input-group-prepend>
                        <b-form-input
                            id="service-charge-per-hour"
                            v-model="location.service_charge"
                            placeholder=""
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>


              <b-col cols="12">
                <b-row>
                  <div class="bank-details">
                    Bank Details
                  </div>

                  <div class="optional-class">
                    Optional
                  </div>
                </b-row>
              </b-col>

              <b-col cols="12" class="pl-0">
                <div class="mt-3">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                          label="Bank Name"
                          label-for="v-bank-name"
                      >
                        <b-form-input
                            id="v-bank-name"
                            v-model="bank_details.name"
                            placeholder="e.g.Bank of England"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col v-if="bank_details.name" md="6">
                      <b-form-group
                          label="Account Number"
                          label-for="account-number"
                      >

                        <validation-provider
                            #default="{ errors }"
                            name="Account Number"
                            rules="required"
                        >
                          <b-form-input
                              id="account-number"
                              v-model="bank_details.account_number"
                              placeholder="xxxxxxxx"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </b-col>
                    <b-col v-if="bank_details.name" md="6">
                      <b-form-group
                          label="Sort Code"
                          label-for="sort-code"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Sort Code"
                            rules="required|min_sort_code|max_sort_code"
                        >
                          <b-form-input
                              id="sort-code"
                              v-model="bank_details.sort_code"
                              placeholder="xx-xx-xx"

                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!--          <b-button-->
                  <!--              variant="primary"-->
                  <!--          >-->
                  <!--            <span class="align-middle">Create Location</span>-->

                  <!--            <feather-icon-->
                  <!--                icon="ChevronRightIcon"-->
                  <!--                class="ml-50"-->
                  <!--            />-->
                  <!--          </b-button>-->

                  <b-form-group class="mb-0">
                    <b-button
                        :disabled="checkLocationDetails"
                        class="btn-sm-block mt-3"
                        variant="primary"
                        @click.prevent="changeTab('Staff')"
                    >

                      <span class="align-middle mb-3">Next</span>
                      <feather-icon
                          class="ml-50 text-light"
                          icon="ChevronRightIcon"

                      />
                    </b-button>
                  </b-form-group>
                </div>
              </b-col>

            </b-card>
          </div>

          <!--      //add staff-->
          <div v-show="current_tab === 'Staff'" class="m-2">
            <b-card>
              <b-col cols="12">
                <span>Add staff that will be working from this location.</span>
              </b-col>

              <b-col class="pb-0 mt-1" cols="12" md="12">
                <b-input-group class="input-group-merge mb-1">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon"/>
                  </b-input-group-prepend>
                  <b-form-input v-model="filters['search']" placeholder="Search"/>
                </b-input-group>
              </b-col>

              <!--          <pre>{{checkedStaffMembers}}</pre>-->
              <!--      <vue-perfect-scrollbar-->
              <!--          :settings="perfectScrollbarSettings"-->
              <!--          class="todo-task-list-wrapper list-group scroll-area"-->
              <!--          style="height: 357px;border: 1px solid #E5E5E5;border-radius: 4px;"-->
              <!--      >-->

              <span>{{ pcnStaffRecords }}</span>


<!--              <ul class="list-group">-->
<!--&lt;!&ndash;                {{detailsOfMember}}&ndash;&gt;-->


<!--                <li-->
<!--                    v-for="(role,index) in pcnStaff"-->
<!--                    :key="role.id"-->
<!--                    class="todo-item  list-group-item py-1"-->
<!--                >-->
<!--                  <validation-provider-->
<!--                      #default="{ errors }"-->
<!--                      name="Staff"-->
<!--                      rules="required"-->
<!--                  >-->

<!--                    &lt;!&ndash;              <b-form-checkbox&ndash;&gt;-->
<!--                    &lt;!&ndash;                  :checked="role.isCompleted"&ndash;&gt;-->
<!--                    &lt;!&ndash;                  v-model="checkedStaffMembers"&ndash;&gt;-->

<!--                    &lt;!&ndash;              >&ndash;&gt;-->
<!--                    <span class="todo-title font-weight-bolder">{{ index }}</span>-->
<!--                    <b-form-checkbox-->
<!--                        v-model="checkedStaffMembers"-->
<!--                        :value="role"-->
<!--                        @change="getStaffAccordingToRole(role)"-->
<!--                    >-->
<!--                    <span class="todo-title font-weight-bolder">Select all</span>-->
<!--                    &lt;!&ndash;              </b-form-checkbox>&ndash;&gt;-->
<!--                    &lt;!&ndash;                  <pre>{{checkedStaffMembers}}</pre>&ndash;&gt;-->
<!--&lt;!&ndash;                      {{checkedStaffMembers}}&ndash;&gt;-->
<!--                    <span-->
<!--                        v-for="(person, index) in role"-->
<!--                        class="todo-item list-group-item py-1"-->
<!--                    >-->
<!--                <b-form-checkbox-->
<!--                    v-model="checkedStaffMembers"-->
<!--                    :value="person"-->
<!--                    @change="getStaff(person)"-->
<!--                >-->

<!--                <span>{{ person.user.first_name + ' ' + person.user.last_name }}</span>-->
<!--                  <span>{{ ' - ' + person.practice.name }}</span>-->
<!--                  <b-badge v-if="person.is_locum == 1" class="mr-1 mb-1" style="padding:0.8rem" variant="light-info">-->
<!--                  <span class="text-dark font-weight-700">L</span>-->
<!--                  </b-badge>-->
<!--                </b-form-checkbox>-->

<!--              </span>-->

<!--                    <small class="text-danger">{{ errors[0] }}</small>-->
<!--                    </b-form-checkbox>-->
<!--                  </validation-provider>-->
<!--                </li>-->

<!--              </ul>-->


              <b-form-checkbox
                  id="All"
                  name="All"
                  class="mt-2 mb-2"
                  @change.native="SelectOrUnselectAll($event)"
              >
                <span class="todo-title font-weight-bolder">Select All</span>
              </b-form-checkbox>

              <ul class="list-group">
                <li
                    v-for="(role) in pcnStaff"
                    class="todo-item  list-group-item py-1"
                >
                  <!--                  <validation-provider-->
                  <!--                      #default="{ errors }"-->
                  <!--                      name="Staff"-->
                  <!--                      rules="required"-->
                  <!--                  >-->
                  <b-form-checkbox
                      :value="role.name"
                      :id="role.name"
                      v-model="all"
                      @change.native="checkGroup(role, $event)"
                  >
                    <span class="todo-title font-weight-bolder" v-if="role.name">{{ role.name }}</span>
                  </b-form-checkbox>
                  <span
                      v-for="(person,index) in role.array"
                      class="todo-item list-group-item py-1"
                  >
                      <b-form-checkbox
                          :value="person"
                          :id="role.name+'_'+person.user_id"
                          v-model="checkedStaffMembers"
                          @change.native="getStaffs(person,role, $event)"
                      >
                      <span>{{ person.user_name }}</span>
                      <span>{{ ' - ' + person.practice_name }}</span>
                      <b-badge v-if="person.is_locum == 1" class="mr-1 mb-1" style="padding:0.8rem" variant="light-info">
                      <span class="text-dark font-weight-700">L</span>
                      </b-badge>
                      </b-form-checkbox>
                    </span>
                  <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
                  <!--                  </validation-provider>-->
                </li>
              </ul>

              <!--      </vue-perfect-scrollbar>-->

            </b-card>
          </div>


          <b-col v-if="current_tab === 'Staff'" class="ml-2" cols-12>
            <b-button

                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="checkLocationAndStaffDetails"
                class="mr-1"
                type="submit"
                variant="primary"
                @click="createLocationMethod()"
            >
              Save
            </b-button>

            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel()"
            >
        <span class="text-secondary">
          Cancel
        </span>
            </b-button>
          </b-col>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {tasks} from './todoTO_BE_REMOVED'
import GeneralDetails from './GeneralDetails'
import Staff from './Staff'
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BRow,
  BSidebar
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import locations from '@/apis/modules/locations'


import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

export default {

  components: {
    BOverlay,
    BCard,
    BCol,
    BRow,
    BInputGroupPrepend,
    GeneralDetails,
    Staff,
    BBadge,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,

    // Form Validation
    ValidationProvider,
    ValidationObserver

  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewLocationSidebarActive',
    event: 'update:is-add-new-location-sidebar-active'
  },
  props: {
    isAddNewLocationSidebarActive: {
      type: Boolean,
      required: true
    }
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data () {
    return {
      loading: false,
      isLocationSamePractice: false,
      selectedPractice: '',
      practicesOption: [],
      practiceShow: false,
      required,
      start_date: '',
      end_date: '',
      location: {
        name: '',
        address: '',
        city: '',
        postal_code: '',
        telephone: '',
        email: '',
        service_charge: ''
      },
      bank_details: {
        name: '',
        account_number: '',
        sort_code: ''
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      filters: {},
      filter: null,
      filterOn: [],
      pcnStaff: [],
      checkedStaffMembers: [],
      selectedStaffPayload: [],
      // staff: {
      //   user_id:null,
      //   practice_id:null,
      //   is_locum:false
      // },
      detailsOfMember: [],

      tasks: tasks,
      practices: [],
      current_tab: 'General-Details',
      isActive: true,
      pcnStaffRecords: '',
      AllStaff: [],
      all:[]
    }
  },
  watch: {
    selectedPractice (val) {
      this.setPracticeData(val)
    },
    checkedStaffMembers () {
     // console.log('aaa',this.checkedStaffMembers)
    },
      isAddNewLocationSidebarActive(val){
        if(val) this.getPcnStaffJobRoleWise();
      },
  },
  computed: {
    checkLocationDetails () {
      if (this.location.name && this.location.address && this.location.city && this.location.postal_code) {
        return !this.isActive
      } else {
        return this.isActive === true
      }
    },
    checkLocationAndStaffDetails () {
      if (this.location.name && this.location.address && this.location.city && this.location.postal_code && this.checkedStaffMembers.length != 0) {
        return !this.isActive
      } else {
        return this.isActive === true
      }
    }
  },
  methods: {
    SelectOrUnselectAll(e){
      this.pcnStaff.forEach((role, index) => {
        role.array.forEach((val, i) => {
          if(e.target.checked === true) {
            if(!this.checkedStaffMembers.some(o => o.practice_id == val.practice_id && o.user_id == val.user_id)){
              this.checkedStaffMembers.push(val)
            }
            document.getElementById(role.name).checked = true
          }else{
            this.checkedStaffMembers = []
            document.getElementById(role.name).checked = false
          }
        })
      })
    },

    checkGroup (option, e) {
      this.pcnStaff.forEach((value, index) => {
        if (value.name === option.name && e.target.checked === true) {
          value.array.forEach((val, i) => {
            if(!this.checkedStaffMembers.some(o => o.practice_id == val.practice_id && o.user_id == val.user_id)){
              this.checkedStaffMembers.push(val)
            }
          })
        } else if (value.name === option.name && e.target.checked === false) {
          option.array.forEach((val, i) => {
            this.checkedStaffMembers.forEach((val2, i2) => {
              if (val.user_id === val2.user_id && val.practice_id === val2.practice_id) {
                const index = this.checkedStaffMembers.indexOf(val2)
                this.checkedStaffMembers.splice(index, 1)
              }
            })
          })
        }
        this.checkSelect()
      })
    },

    getStaffs (record, role, e) {
      this.detailsOfMember.push(record.is_locum, record.user_id, record.practice_id)
      this.pcnStaff.forEach((value, index) => {
        if (value.name === role.name && e.target.checked === true) {
          if (value.array.every(r => this.checkedStaffMembers.includes(r))) {
            // console.log('Found all of value array in checkedStaffMembers')
            document.getElementById(role.name).checked = true
          } else {
            // console.log('Did not find all of value.array in selectedObjs')
          }
          this.checkSelect()
        } else if (value.name === role.name && e.target.checked === false) {
          if (!value.array.every(r => this.checkedStaffMembers.includes(r))) {
            document.getElementById(role.name).checked = false
            this.checkSelect()
          }
        }
        if(document.getElementById('All').checked === true){
          document.getElementById(role.name).checked = true
        }
      })
    },

    async checkSelect(){
      let count = 0
      this.pcnStaff.forEach((value, index) => {
        value.array.forEach((val, i) => {
          count = count+1
        })
      })
      if(count ===  this.checkedStaffMembers.length){
        document.getElementById('All').checked = true
      }else{
        document.getElementById('All').checked = false
      }
    },

    toggle () {
      this.$emit('toggleSideBars')
    },

    cancel () {
      this.current_tab = 'General-Details'
      this.location.name = ''
      this.location.address = ''
      this.location.city = ''
      this.location.email = ''
      this.location.postal_code = ''
      this.location.service_charge = ''
      this.location.telephone = ''
      this.bank_details.name = ''
      this.bank_details.account_number = ''
      this.bank_details.sort_code = ''
      this.checkedStaffMembers = []
      this.filters = []
      this.$emit('toggleSideBars')
    },
    changeTab (text) {
      this.current_tab = text
    },
    async setPracticeData (val) {
      try {
        this.location.name = val.practices.name
        this.location.address = val.practices.address
        this.location.city = val.practices.city
        this.location.email = val.practices.email
        this.location.postal_code = val.practices.postal_code
        this.location.service_charge = val.practices.service_charge
        this.location.telephone = val.practices.phone_number
      } catch (error) {
        this.convertAndNotifyError(error)

      }
    },
    async geConnectedPractice (val) {
      if (val) {
        this.selectedPractice = ''
        this.practiceShow = true
        this.loading = true
        const practices = (await locations.getConnectedLocation()).data.data
        this.practicesOption = practices.map((x) => ({
          title: x.practice.name,
          id: x.practice_id,
          practices: x.practice
        }))
        this.loading = false
      } else {
        this.practiceShow = false
        this.location.name = ''
        this.location.address = ''
        this.location.city = ''
        this.location.email = ''
        this.location.postal_code = ''
        this.location.service_charge = ''
        this.location.telephone = ''
        this.bank_details.name = ''
        this.bank_details.account_number = ''
        this.bank_details.sort_code = ''
        this.checkedStaffMembers = []

      }

    },

    getStaff (record) {
      this.detailsOfMember.push(record.is_locum, record.user_id, record.practice_id)
    },

    // getStaffAccordingToRole(records) {
    //   if(this.AllStaff.length < 1) {
    //     this.AllStaff.push(records)
    //   }else {
    //     for (let i = this.AllStaff.length - 1; i <= this.AllStaff.length; i--) {
    //       if (i === -1) {
    //         this.AllStaff.push(records)
    //         return this.AllStaff
    //       } else {
    //         if (this.AllStaff[i][0].job_role_id === records[0].job_role_id) {
    //           this.AllStaff.splice(i, 1)
    //           let staff = []
    //           let Staffs =[]
    //           for(let i = this.AllStaff.length - 1 ; i<=this.AllStaff.length; i--) {
    //             if (i < 0) {
    //               this.checkedStaffMembers = Staffs
    //               return this.checkedStaffMembers
    //             } else {
    //               staff = this.AllStaff[i].map((x) => x)
    //               Staffs=Staffs.concat(staff)
    //             }
    //           }
    //             return this.AllStaff
    //
    //         } else {
    //
    //         }
    //       }
    //     }
    //
    //   }
    // },

    async getPcnStaffJobRoleWise () {
      // const response = await locations.getPcnStaffJobRoles(this.filterQuery)
      // this.pcnStaff = response.data.data
      // this.pcnStaffRecords = (this.pcnStaff.length === 0 ? 'Please connect to practice to show staff.' : 'Please select staff.')


      try {
        this.staffLoading = true

        const response=await locations.getPcnStaffJobRoles(this.filterQuery)

        const test = Object.entries(response.data.data)
        this.pcnStaff =[]
        test.forEach((x)=>{
          let obj = {
            name:  x[0],
            array: x[1].map((x)=>({
              user_name:x.user.first_name +' ' +x.user.last_name,
              user_id:x.user.id,
              practice_name:x.practice.name,
              practice_id:x.practice.id,
              is_locum:x.is_locum

            }))
          }
          this.pcnStaff.push(obj)
        })
        this.pcnStaffRecords = (this.pcnStaff.length === 0 ?
            'Please connect to practice to show staff.': 'Please select staff.')
        this.staffLoading = false

      }catch (error){
        this.convertAndNotifyError(error)
        this.staffLoading = false

      }
    },
    // getFormData() {
    //   return [this.staff]
    // },
    async createLocationMethod () {

      if (await this.$refs.CreateLocationForm.validate()) {
        try {
          // console.log(this.selectedStaffPayload)
          this.loading = true
          // console.log('checkedStaffMembers',this.checkedStaffMembers)
          const selectedStaffPayload = await this.checkedStaffMembers.map((x) => ({
            user_id: x.user_id,
            practice_id: x.practice_id,
            is_locum: x.is_locum
          }))
          const payload = {
            location: this.location,
            staff: selectedStaffPayload
          }
          if (this.bank_details.name) {
            payload.bank_details = this.bank_details
          }
          const Response = await locations.createLocation(payload)
          if (Response.data.error) {
            this.showErrorMessage('This Location Is Already Exists')
          } else {
            this.showSuccessMessage('Location Created Successfully')
            this.ClearCreateLocationForm()
            this.toggle()
          }
          this.loading = false
          // this.$refs.AddLocationSidebar.refresh()
          this.$emit('CreateLocation')
        } catch (error) {
          this.loading = false
          this.convertAndNotifyError(error)
        }
      }
      this.current_tab = 'General-Details'
    },
    filterQueryUpdate () {
      this.getPcnStaffJobRoleWise();
      // this.setDetails();
    },
    ClearCreateLocationForm () {
      this.location.name = ''
      this.location.address = ''
      this.location.city = ''
      this.location.email = ''
      this.location.postal_code = ''
      this.location.service_charge = ''
      this.location.telephone = ''
      this.bank_details.name = ''
      this.bank_details.account_number = ''
      this.bank_details.sort_code = ''
      this.checkedStaffMembers = []
    }
  },
  async mounted () {
    await this.getPcnStaffJobRoleWise()

    //await this.getLocation()
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";

</style>
<style>
.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 0px;
  margin-top: 3px;
  /*top: calc(50% - 21px/2 + 162px);*/

  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height, or 150% */
  text-align: right;

  /* $dark-grey */
  color: #949494;
}

.bank-details {
  position: absolute;
  /*height: 20.19px;*/
  left: 0%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/

  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  /* Dark */
  color: #171822;
}

.test_cursor input{
  cursor: pointer;
}

</style>