<template>
  <div>

    <b-card class="mb-0" no-body>
      <div class="m-1">
        <div class="row ">

          <!-- Search Button -->

          <b-col  cols="12" md="4" class="pb-0">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
              v-model="filters['search']"
              placeholder="Search" />
            </b-input-group>
          </b-col>


          <!-- Create Programme Button -->


          <b-col class="mt-2 mt-md-0 flex-float-right" cols="12" md="8">
            <b-form-group class="mb-0">
              <b-button
                  class="btn-sm-block float-right"
                  variant="primary"
                  @click="isAddNewLocationSidebarActive = true"
              >
                <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                />
                <span class="align-middle">Create Location</span>
              </b-button>
            </b-form-group>
          </b-col>
        </div>
        <!--        <div class="d-md-flex  ">
                  <b-input-group class="input-group-merge col-12 col-md-4 px-0">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon"/>
                    </b-input-group-prepend>
                    <b-form-input placeholder="Search"/>
                  </b-input-group>

                  <div class="align-self-end ml-auto w-10 mt-2 mt-md-0 ">
                    <b-button class="btn-block" variant="primary" @click="isAddNewLocationSidebarActive = true"
                    >
                      <feather-icon
                          class="mr-50"
                          icon="PlusIcon"/>
                      <span class="align-middle">Add Location</span>
                    </b-button>
                  </div>
                </div>-->

        <b-row>
          <b-col cols-12>
          <b-col class="mt-2">
            <b-overlay :show="tableLoading" rounded="sm">
            <b-table
                ref="refUserListTable"
                :current-page="currentPage"
                :fields="fields"
                :items="getLocationsList"
                :per-page="10"
                class="position-relative mobile_table_css" empty-text="No matching records found"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                primary-key="id"
                responsive
                show-empty
                @row-clicked="myRowClickHandler"
            >

<!--              <template #cell(staff)="data">-->

<!--                &lt;!&ndash; avatar group &ndash;&gt;-->
<!--                <b-avatar-group-->
<!--                    size="34px"-->
<!--                >-->
<!--                  <b-avatar-->
<!--                      v-for="avatar in [5,7,8,9,10]"-->
<!--                      :key="avatar.avatar"-->
<!--                      v-b-tooltip.hover.bottom="avatar.fullName"-->
<!--                      :src="require(`@/assets/images/portrait/small/avatar-s-${avatar}.jpg`)"-->
<!--                      class="pull-up"-->
<!--                  />-->
<!--                  <h6 class="align-self-center cursor-pointer ml-1 mb-0">-->
<!--                    +12-->
<!--                  </h6>-->
<!--                </b-avatar-group>-->

<!--              </template>-->

<!--              <template #cell(map)="data">-->
<!--                <b-button-->
<!--                    class="btn-icon"-->
<!--                    variant="flat-success"-->
<!--                >-->
<!--                  <feather-icon icon="MapIcon"/>-->
<!--                </b-button>-->
<!--              </template>-->
            </b-table>
            </b-overlay>
          </b-col>
          <b-row>
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
          </b-col>

          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
          </b-row>
          </b-col>
        </b-row>




        <!--                pagination-->
<!--        <div class="mx-1 mb-2">-->
<!--          <b-row>-->

<!--            <b-col-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--            >-->
<!--              <span class="text-muted">Showing 1 to 2 of 2 entries</span>-->
<!--            </b-col>-->
            <!-- Pagination -->
<!--            <b-col-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--            >-->

<!--              <b-pagination-->
<!--                  v-model="currentPage"-->
<!--                  :per-page="10"-->
<!--                  :total-rows="2"-->
<!--                  class="mb-0 mt-1 mt-sm-0 "-->
<!--                  first-number-->
<!--                  last-number-->
<!--                  next-class="next-item"-->
<!--                  prev-class="prev-item"-->
<!--              >-->
<!--                <template #prev-text>-->
<!--                  <feather-icon-->
<!--                      icon="ChevronLeftIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </template>-->
<!--                <template #next-text>-->
<!--                  <feather-icon-->
<!--                      icon="ChevronRightIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </template>-->
<!--              </b-pagination>-->

<!--            </b-col>-->

<!--          </b-row>-->
<!--        </div>-->
      </div>
    </b-card>

    <AddLocationSideBar
        ref="AddLocationSideBar"
        :is-add-new-location-sidebar-active.sync="isAddNewLocationSidebarActive"
        @toggleSideBars="toggleSideBars()"
        @CreateLocation="SaveNewLocation()"
    />
<!--    <CreateNewLocationFormWizardSideBar-->
<!--        ref="CreateNewLocationFormWizardSideBarSideBar"-->
<!--        :is-create-new-location-form-wizard-sidebar-active.sync="isCreateNewLocationFormWizardSidebarActive"-->
<!--        @toggleSideBars="toggleSideBars()"-->
<!--    />-->

  </div>
</template>

<script>
import locations from '@/apis/modules/locations'
import AddLocationSideBar from '@/views/locations/sidebars/AddLocationsideBar.vue'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'map-list',
  components: {
    AddLocationSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },

  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data: function () {
    return {
      tableLoading:false,
      currentPage: 1,
      isAddNewLocationSidebarActive: false,
      isCreateNewLocationFormWizardSidebarActive: false,
      filters: {},
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      sortDirection: "asc",

      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },

      fields: [
        {
          key: 'name',
          // sortable: true
        },
        {
          key: 'address',
          // sortable: true
        },
        {
          key: 'city',
          // sortable: true
        },
        {
          key: 'postal_code',
          // sortable: true
        },
        {
          key: 'staff',
          // sortable: true
        },
        // {
        //   key: 'map',
        //   sortable: true
        // }
      ],
    }
  },

  mounted () {
    this.getLocationsList()
  },

  methods: {
    SaveNewLocation () {
      this.getLocationsList()
      this.$refs.refUserListTable.refresh()
    },
    toggleSideBars() {
      this.isAddNewLocationSidebarActive = !this.isAddNewLocationSidebarActive
      this.isCreateNewLocationFormWizardSidebarActive = !this.isCreateNewLocationFormWizardSidebarActive
    },
    myRowClickHandler(item) {
      this.$router.push(`/locations/${item.id}`)
    },

    async getLocationsList () {
      try{
        this.tableLoading = true;
        const Response = await locations.getLocations(this.currentPage,10, this.filterQuery)
        const dataArray = Response.data.data.map((x) => ({
          id:x.id,
          name:x.name,
          address:x.address,
          city:x.city,
          postal_code:x.postal_code,
          staff:x.location_staff_count
        }))

        //console.log(dataArray)
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        return dataArray
      }catch(e) {
        this.tableLoading = false;
      }
    },
    filterQueryUpdate () {
      this.$refs.refUserListTable.refresh()
    },
  }
}
</script>

<style scoped>

</style>
